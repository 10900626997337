import React from 'react';
import Carousel from './carousel';
import CenteredHeroe from './centered-heroe';
import GridContainer from './grid-container';

const Home = () => {
    return (
        <>
            <Carousel></Carousel>
            <CenteredHeroe></CenteredHeroe>
            <GridContainer></GridContainer>
        </>
    );
}

export default Home;