import React from 'react';
import about_us from '../assets/bfcnc_about.jpg'

const About = () => {
    return (
        <div className="container custom-container">
            <div className="row about-us-info-container">
                <h1>About Us</h1>
                <img src={about_us} alt="Bichon Frise Club of Northern California"></img>
                <p>We are a not-for-profit organization dedicated to encouraging and promoting quality in the breeding of purebred Bichons Frises and to do all possible to bring their natural qualities to perfection.</p>
            </div>
            <div className="row about-us-officers-container">
                <h4>Bichon Frise Club of Northern California Board</h4>
                <div className="about-us-officers-table">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Officer</th>
                                <th scope="col">Department</th>
                            </tr>
                        </thead>
                        <tbody className="table-group-divider">
                            <tr>
                                <td><a href="mailto:donnademar31@comcast.net" target="_blank">Donna DeMartini</a></td>
                                <td>President</td>
                            </tr>
                            <tr>
                                <td><a href="mailto:aquevedo81@gmail.com" target="_blank">Alejandra Quevedo</a></td>
                                <td>Vice President</td>
                            </tr>
                            <tr>
                                <td><a href="mailto:andreafrancessf2@yahoo.com" target="_blank">Andrea Cunningham</a></td>
                                <td>Secretary</td>
                            </tr>
                            <tr>
                                <td><a href="mailto:jadorablebichons@gmail.com" target="_blank">Jennifer Eikmeier</a></td>
                                <td>Treasurer</td>
                            </tr>
                            <tr>
                                <td><a href="mailto:jadorablebichons@gmail.com" target="_blank">Becky Buckman</a></td>
                                <td>Member at large</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row about-us-bylaws-container">
                <h4>Club Bylaws</h4>
                <a href="http://www.bfcnc.net/BFCNCConstitutionByLaws2020.pdf" target="_blank">BFCNC Constitution ByLaws - 2020</a>
                <a href="http://www.bfcnc.net/BFCNCStandingRules2020.pdf" target="_blank">BFCNC Standing Rules - 2020</a>
            </div>
        </div>
    );
}

export default About;