import React from 'react';

const Breeders = () => {
    return (
        <div className="container custom-container">
            <div className="row breeders-info-container">
                <h1>Directory of Breeders, Products & Services</h1>
                <p>The Bichon Frise Club of Northern California Directory of Breeders, Products & Services is provided for information only.  The listings subscribed to by these members do not represent or imply any guarantee, warranty or other such assertion by BFCNC.  Links are provided solely as a convenience to you.  BFCNC does not endorse or make any representations about them, or any information, products or materials found there, or any results that may be obtained from using them.  If you decide to access any of these sites, it is at your own risk.</p>
                <p>Legend:</p>
                <div className="col-6 col-md-4 col-lg-3 breeders-info-legend-table-container">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>P</td>
                                <td>=</td>
                                <td>Puppy</td>
                            </tr>
                            <tr>
                                <td>A</td>
                                <td>=</td>
                                <td>Adult</td>
                            </tr>
                            <tr>
                                <td>I</td>
                                <td>=</td>
                                <td>Breed Information</td>
                            </tr>
                            <tr>
                                <td>G</td>
                                <td>=</td>
                                <td>Grooming</td>
                            </tr>
                            <tr>
                                <td>T</td>
                                <td>=</td>
                                <td>Training</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p>Underlined Breeder Names are hyperlinked to last known email address. Underlined Kennel Names are hyperlinked to web page.</p>
            </div>
            <div className="row breeders-info-table">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">State</th>
                            <th scope="col">City</th>
                            <th scope="col">Breeder's Name</th>
                            <th scope="col">Kennel Name</th>
                            <th scope="col">Phone Number(s)</th>
                            <th scope="col">Services</th>
                        </tr>
                    </thead>
                    <tbody className="table-group-divider">
                        <tr>
                            <td>California</td>
                            <td>Granite Bay</td>
                            <td><a href="mailto:demartinidonna991@gmail.com" target="_blank">Donna de Martini</a></td>
                            <td>DeMartini's Bichons</td>
                            <td>(916) 847 0151</td>
                            <td>(P)</td>
                        </tr>
                        <tr>
                            <td>California</td>
                            <td>El Dorado Hills</td>
                            <td><a href="mailto:pritznitz@gmail.com" target="_blank">Tom & Jennifer Eikmeier</a></td>
                            <td><a href="https://jadorablebichons.com/" target="_blank">J'​adorable ​Bichons</a></td>
                            <td>(916) 941 0486 / (916) 390 1621</td>
                            <td>(P) (A) (I) (G) (T)</td>
                        </tr>
                        <tr>
                            <td>California</td>
                            <td>Napa</td>
                            <td><a href="mailto:lemonhillbichons@gmail.com" target="_blank">Don & Theresa Clyde</a></td>
                            <td>Lemon Hills Bichons</td>
                            <td>(707) 330 0199</td>
                            <td>(P)</td>
                        </tr>
                        <tr>
                            <td>California</td>
                            <td>Oakland</td>
                            <td><a href="mailto:info@goldenbaybichons.com" target="_blank">Manuel Hernandez</a></td>
                            <td><a href="https://goldenbaybichons.com/" target="_blank">Golden Bay Bichons</a></td>
                            <td>(510) 828 9168</td>
                            <td>(P) (A) (I) (G) (T)</td>
                        </tr>
                        <tr>
                            <td>California</td>
                            <td>Simi Valley</td>
                            <td><a href="mailto:lianne.bondurant@gmail.com" target="_blank">Lianne Bondurant</a></td>
                            <td>Scarlets Kennels</td>
                            <td>(805) 750 1418</td>
                            <td>(P) (A) (I) (G) (T)</td>
                        </tr>
                        <tr>
                            <td>California</td>
                            <td></td>
                            <td><a href="mailto:jyhartmann@hotmail.com" target="_blank">Janet Hartmann</a></td>
                            <td>Shira Yuri</td>
                            <td></td>
                            <td>(P) (A)</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Breeders;