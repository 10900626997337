import React from 'react';
import {Link} from "react-router-dom";
import bfcnc_logo from "../assets/bfcnc_logo.png";

const Navbar = () => {
  return (
      <>
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
          <div className="container">
            <a className="navbar-brand" href="#">
              <img src={bfcnc_logo} height="100px" />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarColor01">
              <ul className="navbar-nav me-auto">
                <li className="nav-item">
                  <Link to="/" className="nav-link">Home</Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className="nav-link">About us</Link>
                </li>
                <li className="nav-item">
                  <Link to="/breeders" className="nav-link">Breeders Directory</Link>
                </li>
                <li className="nav-item">
                  <Link to="/events" className="nav-link">Events</Link>
                </li>
                <li className="nav-item">
                  <Link to="/gallery" className="nav-link">Gallery</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </>
  );
}

export default Navbar;
