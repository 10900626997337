import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Navbar from './components/navbar';
import Footer from './components/footer';
import About from './components/about';
import Home from './components/home';
import Breeders from './components/breeders';
import Events from './components/events';
import Gallery from './components/gallery';
import AKCStandard from './components/akc-standard';

const App = () => {
  return (
    <div className="">
      <BrowserRouter>
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About/>} />
          <Route path="/breeders" element={<Breeders/>} />
          <Route path="/events" element={<Events/>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/akc-standard" element={<AKCStandard />} />
        </Routes>
      <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;