import React from "react";
import {Link} from "react-router-dom";

const CenteredHeroe = () => {
    return (
        <div className="px-4 py-5 my-5 text-center">
            <h1 id="mainTitle" className="display-5 fw-bold">Welcome to the official Bichon Frise Club of Northern California!</h1>
            <br />
            <div className="col-lg-6 mx-auto">
                <p className="lead mb-4">We are a not-for-profit organization dedicated to encouraging and promoting quality in the breeding of purebred Bichons Frises and to do all possible to bring their natural qualities to perfection.</p>
                <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                    <Link to="/breeders" className="btn btn-primary btn-lg px-4 gap-3">Breeders Info</Link>
                    <Link to="/akc-standard" className="btn btn-outline-secondary btn-lg px-4">AKC Standard</Link>
                </div>
            </div>
        </div>
    )
}
export default CenteredHeroe;