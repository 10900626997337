import React from "react"
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="container-fluid footer-container">
            <div className="container">
                <footer className="pt-5">
                    <div className="row">
                        <div className="footer-section col col-md-4 col-xl-7 mb-3">
                            <h5>Section</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-muted">Home</Link></li>
                                <li className="nav-item mb-2"><Link to="/about" className="nav-link p-0 text-muted">About us</Link></li>
                                <li className="nav-item mb-2"><Link to="/breeders" className="nav-link p-0 text-muted">Breeders Directory</Link></li>
                                <li className="nav-item mb-2"><Link to="/events" className="nav-link p-0 text-muted">Events</Link></li>
                                <li className="nav-item mb-2"><Link to="/gallery" className="nav-link p-0 text-muted">Gallery</Link></li>
                            </ul>
                        </div>
                        <div className="col col-md-8 col-xl-5 mb-3">
                            {/* <form>
                                <h5>Subscribe to our newsletter</h5>
                                <p>Monthly digest of what's new and exciting from us.</p>
                                <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                                    <label for="newsletter1" className="visually-hidden">Email address</label>
                                    <input id="newsletter1" type="text" className="form-control" placeholder="Email address" />
                                    <button className="btn btn-primary" type="button">Subscribe</button>
                                </div>
                            </form> */}
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                        <p>&copy; 2022 Bichon Frise Club of Northern California, Inc. All rights reserved.</p>
                    </div>
                </footer>
            </div>
        </div>
    )
}
export default Footer;