import React from 'react';

const Events = () => {
    return (
        <div className="container custom-container">
            <div className="row events-info-container">
                <h1>Events</h1>
                <p>Event coordinator: <a href="mailto:donnademar31@comcast.net" target="_blank">Donna DeMartini</a></p>
            </div>
            <div className="events-header-container">
                <h4>Upcoming Events</h4>
            </div>
            <div className="row events-posts-container">
                <div className="col-12">
                    <div className="row g-0 border overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                        <div className="col p-4 d-flex flex-column position-static">
                            <strong className="d-inline-block mb-2 text-primary">Designated Specialty</strong>
                            <h3 className="mb-0">Designated Specialty Show</h3>
                            <div className="mb-3 text-muted">When: Friday October 18 to Saturday October 20, 2024</div>
                            <p className="card-text">Please join the Bichon Frise Club of Northern California in Santa Rosa, California with three days of shows!!!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Events;