import React from 'react';

const GridElement = ({ title_1, title_2, lead_1, lead_2, content_1, content_2, image, bg_color}) => {
    return (

        <div className={"box-column col-12 col-lg-4 " + bg_color}>
            <div className="box-info-wrapper">
                <div className="box-title">
                    <h2 className="display-5">{title_1}</h2>
                    <h2 className="display-5">{title_2}</h2>
                </div>
                <div className="box-lead">
                    <p>{lead_1}</p>
                    <p>{lead_2}</p>
                </div>
                <div className="box-content">
                    <p>{content_1}</p>
                    <p>{content_2}</p>
                </div>
            </div>
            <div className="box-image-wrapper">
                <div className="box-image-relative">
                    <div className="box-image-absolute">
                        <img src={image} className="box-image" alt="..." />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GridElement;