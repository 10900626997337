import React from 'react';
import ResponsiveGallery from "react-responsive-gallery";

const images = [
    {
        src: 'https://bichonfriseclubnortherncalifornia.org/static/media/IMG_0847.jpg',
        alt: 'Bichon frise',
        title: 'Bichon Frise Specialty',
        description: '',
    },
    {
        src: 'https://bichonfriseclubnortherncalifornia.org/static/media/IMG_0833.jpg',
        alt: 'Bichon frise',
        title: 'Bichon Frise Specialty',
        description: '',
    },
    {
        src: 'https://bichonfriseclubnortherncalifornia.org/static/media/IMG_0840.jpg',
        alt: 'Bichon frise',
        title: 'Bichon Frise Specialty',
        description: '',
    },
    {
        src: 'https://bichonfriseclubnortherncalifornia.org/static/media/IMG_8185.jpg',
        alt: 'Bichon frise',
        title: 'Bichon Frise Specialty',
        description: '',
    },
    {
        src: 'https://bichonfriseclubnortherncalifornia.org/static/media/IMG_8188.jpg',
        alt: 'Bichon frise',
        title: 'Bichon Frise Specialty',
        description: '',
    },
    {
        src: 'https://bichonfriseclubnortherncalifornia.org/static/media/IMG_8189.jpg',
        alt: 'Bichon frise',
        title: 'Bichon Frise Specialty',
    },
    {
        src: 'https://bichonfriseclubnortherncalifornia.org/static/media/IMG_0846.jpg',
        alt: 'Bichon frise',
        title: 'Bichon Frise Specialty',
    },
    {
        src: 'https://bichonfriseclubnortherncalifornia.org/static/media/IMG_8186.jpg',
        alt: 'Bichon frise',
        title: 'Bichon Frise Specialty',
    },
    {
        src: 'https://bichonfriseclubnortherncalifornia.org/static/media/IMG_0001.jpg',
        alt: 'Bichon frise',
        title: 'Bichon Frise Specialty',
    },
    {
        src: 'https://bichonfriseclubnortherncalifornia.org/static/media/IMG_0003.jpg',
        alt: 'Bichon frise',
        title: 'Bichon Frise Specialty',
    }
];

const Gallery = () => {
    return (
        <div className="container custom-container">
            <div className="row about-us-info-container">
                <h1>Gallery</h1>
            </div>
            <div className="row">
                <ResponsiveGallery images={images} />
            </div>
        </div>
    );
}

export default Gallery;