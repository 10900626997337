import React from 'react';
import GridElement from './grid-element';
import bichon_frise_03 from '../assets/bichon_frise_03.jpg'
import bichon_frise_04 from '../assets/bichon_frise_04.jpg'
import bichon_frise_05 from '../assets/bichon_frise_05.jpg'

const GridContainer = () => {
  return (
    <div className="d-lg-flex flex-md-equal w-100 grid-container">
      <div className="row">
        <GridElement
          title_1={<a href='https://bichon.org/national-specialty/'></a>}
          title_2=""
          lead_1=""
          lead_2=""
          content_1=""
          content_2=""
          image={bichon_frise_05}></GridElement>
        <GridElement
          title_1={<a href='https://bichon.org/national-specialty/'>Designated Specialty Show</a>}
          lead_1="Friday October 18th"
          lead_2="to Saturday October 20th 2024"
          content_1="Santa Rosa, California"
          content_2=""
          image={bichon_frise_03}
          bg_color="text-bg-light"></GridElement>
        <GridElement
          title_1={<a href='https://bichon.org/national-specialty/'></a>}
          lead_1=""
          content_1=""
          content_2=""
          image={bichon_frise_04}></GridElement>
      </div>
    </div>
  )
}
export default GridContainer;